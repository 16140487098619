import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { passwordPattern } from '../../../core/validators/patterns';
import { matchFieldsValidator } from '../../../core/validators/match-fields-validator';
import { AuthService } from '../../../core/services/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LoaderComponent } from '../../../shared/components/general/loader/loader.component';
import { finalize } from 'rxjs';
import { MessageService } from '../../../core/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PASSWORD_HINT,
  ResponseMessages
} from '../../../core/constants/config';

@Component({
  selector: 'app-create-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    LoaderComponent
  ],
  templateUrl: './create-password.component.html'
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  private messageService = inject(MessageService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  isLoading = false;
  isPasswordHidden = true;
  isConfirmPasswordHidden = true;
  passwordHint = PASSWORD_HINT;
  resetForm = new FormGroup(
    {
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(passwordPattern)
      ]),
      confirm_password: new FormControl(null, [Validators.required])
    },
    [matchFieldsValidator('password', 'confirm_password')]
  );
  get password() {
    return this.resetForm.get('password');
  }
  get confirmPassword() {
    return this.resetForm.get('confirm_password');
  }

  ngOnInit(): void {
    this.authService.pageIndexLoader();
  }

  ngOnDestroy(): void {
    this.authService.hidePageElements();
  }

  reset() {
    if (this.isLoading) {
      return;
    }
    const code = this.route.snapshot.queryParamMap.get('code');
    if (this.resetForm.valid && code) {
      this.isLoading = true;
      this.authService
        .resetPassword({ ...this.resetForm.value, code })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.resetForm.reset();
          })
        )
        .subscribe((response: any) => {
          this.router.navigate(['/']);
          this.messageService.setSuccessMessage(
            ResponseMessages.USER_PASSWORD_CREATED
          );
        });
    }
  }
}
