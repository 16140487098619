@if(messageDisplay.message){
<div class="header-message">
  <div
    class="d-flex align-items-center justify-content-center"
    [class]="messageDisplay.className"
  >
    <mat-icon class="me-2">error</mat-icon>
    <span class="body">{{ messageDisplay.message }}</span>
  </div>
</div>
}
<div [class.loading]="loadingHttp">
  <router-outlet></router-outlet>
</div>
