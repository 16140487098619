import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { chatSchedule, isMobile } from './app/core/constants/outer-scripts';

isMobile();

if (environment.production) {
  enableProdMode();
  chatSchedule();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
